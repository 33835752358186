<template>
  <section class="footer_99">
    <div class="other_info">
      <div class="info_item">
        <h6>友情链接</h6>
        <ul>
          <li>
            <a href="https://www.meizu.com/" target="_blank" title="魅族商城"
              >魅族商城</a
            >
          </li>
          <li>
            <a href="https://cloud.flyme.cn/" target="_blank" title="云服务"
              >云服务</a
            >
          </li>
          <li>
            <a href="https://www.flyme.com/" target="_blank" title="Flyme官网"
              >Flyme官网</a
            >
          </li>
          <li>
            <a href="https://www.meizu.cn/" target="_blank" title="魅族社区"
              >魅族社区</a
            >
          </li>
        </ul>
      </div>

      <div class="info_item">
        <h6>商务邮箱</h6>
        <ul>
          <li>auto-bd@xjmz.com</li>
        </ul>
      </div>

      <div class="info_item">
        <h6>在线时间</h6>
        <ul>
          <li>周一至周五 9:00 - 18:30</li>
        </ul>
      </div>
    </div>

    <div class="divider_line"></div>

    <div class="explain">
      <div class="content_item content_1">
        <span style="margin-right: 27px"
          >©2023 ZHUHAI XINGJI MEIZU INFORMATION TECHNOLOGY CO.,LTD. All rights
          reserved.</span
        >
        <a
          href="https://www3.res.meizu.com/static/cn/_partial/footer/images/com_licence_b00fe42.jpg"
          title="营业执照"
          target="_blank"
          >营业执照</a
        >
        <span class="middle_line"></span>
        <a href="./use_agreement.html" target="_blank">法律声明</a>
        <span class="middle_line"></span>
        <a href="./knowledge_agreement.html" target="_blank">知识产权声明</a>
      </div>
      <div class="content_item">
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          style="width: 214px"
          target="_blank"
          title="工业和信息化部政务服务平"
          >备案号：鄂ICP备2022014710号-9</a
        >
        <span class="middle_line"></span>
        <a
          href="https://www-res.flyme.cn/resources/flymeos/v4/assets/common/icp-mz.jpg"
          style="width: 214px"
          target="_blank"
          title="经营许可证编号"
          >经营许可证编号：合字B2-20170010</a
        >
        <span class="middle_line"></span>
        <a
          title="广东省互联网违法和不良信息举报中心"
          href="https://www.gdjubao.cn/jb/?harm_type=18"
          style="width: 259px"
          target="_blank"
          >涉未成年人网络有害信息举报 电话：12377</a
        >
        <span class="middle_line"></span>
        <a
          href="https://www.gdjubao.cn/jb/Acceptance/swlblyhxx/"
          style="width: 169px"
          target="_blank"
          title="涉网络暴力有害信息举报专区"
          >涉网络暴力有害信息举报专区</a
        >
        <span class="middle_line"></span>
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44049102496600"
          target="_blank"
          title="公安机关互联网站安全管理服务平台"
        >
          <span class="font_img" bg-url="/ghs.png" v-bg></span
          >粤公网安备44049102496971号
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="less" scoped>
.footer_99 {
  height: 383px;
  background-color: #f7f8fa;

  a {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    display: inline-block;
    text-align: center;

    &:hover {
      text-decoration-line: underline;
      color: #000000;
    }
  }
}

.other_info {
  height: 223px;
  width: 1200px;
  padding-top: 72px;
  margin: auto;
  display: flex;

  .info_item {
    display: inline-block;
    width: 306px;
    height: 100%;

    h6 {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #000;
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 14px 0 0 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.divider_line {
  width: 1200px;
  height: 1px;
  margin: auto;
  background-color: rgba(189, 189, 189, 0.2);
}

.explain {
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);

  .middle_line {
    display: inline-block;
    width: 1px;
    height: 14px;
    background: #bdbdbd;
    opacity: 0.2;
    margin: 0 10px;
  }

  .content_item {
    width: 1200px;
    display: flex;
    align-items: center;
  }
  .content_1 {
    padding: 17px 0 12px 0;
  }

  .font_img {
    display: inline-block;
    width: 15px;
    height: 16px;
    background-size: 100%;
    margin-right: 5px;
    vertical-align: text-top;
  }
}
</style>
